import { useTranslation } from 'next-i18next';
import { JobBenefitsGroup } from '@type/v1-api-types';
import {
  BanknotesIcon, HeartIcon, BookOpenIcon, BriefcaseIcon, SunIcon, CheckIcon,
} from '@heroicons/react/24/outline';
import { HeroIconType } from '@type/icons';
import { Button } from '@components/common';
import { detailSectionsStyles } from '@components/templates/job/detail/detailSectionStyles';
import { route } from '@services/symfony';
import { toSeparatorCase } from '@utils/strings/convertCase';

// Interface
// ************************************
export interface BenefitsProps {
  benefitsGroups: JobBenefitsGroup[]
}

// Icons
// ************************************
export const BenefitGroupsIcon: { [key: number]: HeroIconType } = {
  1: BanknotesIcon,
  2: HeartIcon,
  3: BookOpenIcon,
  4: BriefcaseIcon,
  5: SunIcon,
};

/**
 * @description Job Detail Benefits. Renders job benefits.
 * - **benefitsGroups** - JobBenefitsGroup[].
 */
export const Benefits = (props: BenefitsProps) => {
  // Destructure props
  const { benefitsGroups } = props;

  // Translation
  const { t } = useTranslation('common');

  // Return null if no benefits
  if (!benefitsGroups.length) {
    return null;
  }

  // Render component
  // ************************************
  return (
    <div className={detailSectionsStyles.common}>
      <h3 className={detailSectionsStyles.title}>
        { t('global.benefits') }
      </h3>
      <div className="mt-6 grid grid-cols-1 gap-x-5 gap-y-8 sm:grid-cols-2 md:grid-cols-3">
        {benefitsGroups.map((benefitGroup) => {
          if (!benefitGroup.benefits.length) {
            return null;
          }

          const Icon = BenefitGroupsIcon[benefitGroup.type] || null;

          return (
            <div key={benefitGroup.type}>
              <div className="mb-2.5 flex items-center space-x-2 font-bold">
                {Icon && <Icon className="mt-0.5 size-5 shrink-0" />}
                <span>{benefitGroup.benefits[0].type}</span>
              </div>
              <div className="space-y-2">
                {benefitGroup.benefits.map((benefit) => (
                  <div key={benefit.id} className="flex">
                    <CheckIcon className="mr-2 mt-0.5 size-4 shrink-0" />
                    <Button
                      styling="text"
                      size="sm"
                      className="text-left"
                      onClick={() => window.open(route('app_job_canonical', { params: `${t('url.param.benefits')}/${toSeparatorCase(benefit.name)}` }), '_blank')}
                    >
                      {benefit.name}
                    </Button>
                  </div>
                )) }
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
