import { useMemo } from 'react';
import { generateNumbersArray } from '@utils/arrays/generateNumbersArray';

/**
 * @description Job Detail Summary Placeholder.
 */
export const SummaryPlaceholder = () => {
  const summaryLines = useMemo(
    () => generateNumbersArray(1, 3),
    [],
  );

  return (
    <div className="mt-10 animate-pulse space-y-5">
      {summaryLines.map((index) => (
        <div key={`${index}-`} className="h-2 w-full rounded bg-slate-400 md:w-32" />
      ))}
    </div>
  );
};
