import { useMemo } from 'react';
import { clsx } from 'clsx';
import { detailSectionsStyles } from '@components/templates/job/detail';
import { generateNumbersArray } from '@utils/arrays/generateNumbersArray';

/**
 * @description Job Detail Description Placeholder.
 */
export const DescriptionPlaceholder = () => {
  const descriptionLines = useMemo(
    () => generateNumbersArray(1, 15),
    [],
  );

  return (
    <div className={clsx('animate-pulse space-y-3', detailSectionsStyles.common)}>
      {descriptionLines.map((index) => (
        <div key={`${index}-`} className="h-4 w-full rounded bg-slate-200" />
      ))}
    </div>
  );
};
