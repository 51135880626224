import { ApiJobDetailOk, ApiPrevNextJobOk } from '@utils/data-fetching';
import {
  Button, ImageBj, Tooltip,
  PopoverStyled, PopoverStyledButton, PopoverStyledPanel, PopoverStyledBackdrop,
} from '@components/common';
import { XMarkIcon, ShareIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-i18next';
import { useBjModal } from '@hooks/common/useBjModal';
import { ShareModal } from '@components/features/modals/share-modal/ShareModal';
import { useMemo } from 'react';
import { NavButton } from '@components/templates/job/detail/partials/NavButton';
import { clsx } from 'clsx';
import { SwipeSolidIcon } from '@components/common/Icons';
import swipeHelp from '@images/job/swipe-help.gif';
import { ModalPrevNextJobs } from '@type/job';
import { getUserHeaderInfo } from '@store/reducers/userSelector';
import { useAppSelector } from '@store/index';

// Interface
// ************************************
export interface ShareAndNavigationProps {
  job: ApiJobDetailOk | null;
  context?: 'mobile' | 'desktop';
  apiPrevNextJobs?: ApiPrevNextJobOk | null;
  modalPrevNextJobs?: ModalPrevNextJobs;
  closeJobModal?: () => void;
  className?: string;
  modalNavigateToJob?: (nextJobSlug: string, direction: 'prev' | 'next') => void;
}

/**
 * @description Share And Navigation. Renders the sharing button, prev & next button and close modal button (if opened in modal).
 * - **job** - ApiJobDetailOk - The job object.
 * - **context** - 'mobile' | 'desktop' - The context where is displayed this component. Default 'desktop'.
 * - **apiPrevNextJobs** - ApiPrevNextJobOk - Prev & next jobs, for in page navigation (not modal).
 * - **modalPrevNextJobs** - ModalPrevNextJobs - Prev & next jobs, for modal navigation. The data comes from SWR.
 * - **closeModal** - function - Function to close the modal, if opened in a modal, optional.
 * - **className** - string - Additional classnames, for styling purposes, optional.
 * - **modalPrevNextJobs** - Prev & next jobs, for modal navigation.
 * - **modalNavigateToJob** - function - The function to use to navigate to prev / next job (in modal).
 */
export const ShareAndNavigation = (props: ShareAndNavigationProps) => {
  const {
    job,
    context = 'desktop',
    apiPrevNextJobs,
    modalPrevNextJobs,
    closeJobModal,
    className,
    modalNavigateToJob,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // States for Share Modal
  // *****************************************
  const { isOpen: isOpenShareModal, toggleModal: toggleShareModal } = useBjModal();

  // Helpers
  // *****************************************
  const isJobModal = useMemo(
    () => !!closeJobModal,
    [closeJobModal],
  );

  // Job detail page nav tracking classes
  // ************************************
  const pageNavTrackingClasses = 'tr_job_detail_nav_arrows_single_page';

  // Modal nav tracking classes
  // ************************************
  const modalNavTrackingClasses = useMemo(
    () => (context === 'mobile' ? 'tr_job_detail_nav_arrows_mobile' : 'tr_job_detail_nav_arrows_desktop'),
    [context],
  );

  // Button colors, based on the current context.
  // ************************************
  const buttonColor = useMemo(
    () => (context === 'mobile' ? 'white' : 'light'),
    [context],
  );

  // Button classes, based on the current context.
  // ************************************
  const buttonClass = useMemo(
    () => {
      let classes = '!p-2';
      classes += (context === 'mobile' ? ' dark:bg-surface-100 dark:text-ink' : '');
      return classes;
    },
    [context],
  );

  const headerInfo = useAppSelector(getUserHeaderInfo);

  // Render component
  // ************************************
  return (
    <div className={className}>
      <div className="flex items-center gap-x-4">
        { /* Close modal button */
          closeJobModal && (
            <Tooltip
              tooltipText={t('global.btn.close')}
              position={isJobModal ? 'bottom' : 'top'}
              colorScheme="black"
            >
              <Button
                color={buttonColor}
                className={clsx(buttonClass, 'tr-job-close')}
                size="sm"
                rounding="full"
                tabIndex={job ? 0 : -1}
                onClick={closeJobModal}
              >
                <XMarkIcon className="size-5" />
              </Button>
            </Tooltip>
          )
        }

        {job && (
          <>
            {/* Share job modal button */}
            <Tooltip
              tooltipText={t('job.detail.share.modal.title')}
              position={isJobModal ? 'bottom' : 'top'}
              colorScheme="black"
            >
              <Button
                color={buttonColor}
                className={buttonClass}
                size="sm"
                rounding="full"
                onClick={toggleShareModal}
              >
                <ShareIcon className="size-5" />
              </Button>
            </Tooltip>

            <div className="ml-auto flex items-center gap-x-4">
              {isJobModal ? (
                <>
                  <NavButton
                    direction="prev"
                    disabled={!modalPrevNextJobs?.prevJob}
                    color={buttonColor}
                    className={clsx(buttonClass, modalNavTrackingClasses, 'tr-job-nav-left')}
                    onClick={() => (modalPrevNextJobs?.prevJob?.slug && modalNavigateToJob ? modalNavigateToJob(modalPrevNextJobs?.prevJob.slug, 'prev') : {})}
                  />
                  <NavButton
                    direction="next"
                    disabled={!modalPrevNextJobs?.nextJob}
                    color={buttonColor}
                    className={clsx(buttonClass, modalNavTrackingClasses, 'tr-job-nav-right')}
                    onClick={() => (modalPrevNextJobs?.nextJob?.slug && modalNavigateToJob ? modalNavigateToJob(modalPrevNextJobs?.nextJob.slug, 'next') : {})}
                  />

                  {/* Swipe info popover */}
                  <PopoverStyled>
                    <PopoverStyledButton
                      as={Button}
                      color={buttonColor}
                      rounding="full"
                      className={clsx(buttonClass, 'can-hover:hidden')}
                    >
                      <SwipeSolidIcon className="size-5" />
                    </PopoverStyledButton>

                    {/* Backdrop */}
                    <PopoverStyledBackdrop
                      className="fixed inset-0 z-1 bg-black opacity-30"
                    />

                    {/* Panel */}
                    <PopoverStyledPanel
                      animation="slideUp"
                      position="right"
                      className="w-96 max-w-[90vw] overflow-hidden !rounded-lg !border-t-0"
                    >
                      <h3 className="bg-primary px-6 py-3 text-center font-semibold text-white">
                        { t('help.nav.popover.title') }
                      </h3>
                      <div className="flex flex-col items-center justify-center px-6 pb-8 pt-6">
                        <ImageBj src={swipeHelp} alt={t('help.nav.popover.title')} />
                        <p className="mt-6 text-center">{ t('help.nav.swipe.text') }</p>

                        <PopoverStyledButton
                          as={Button}
                          size="sm"
                          className="mt-6"
                        >
                          { t('job.list.swipemodal.button') }
                        </PopoverStyledButton>
                      </div>
                    </PopoverStyledPanel>
                  </PopoverStyled>
                </>
              ) : (
                <>
                  {apiPrevNextJobs?.prevJob && (
                    <NavButton
                      navigationJob={apiPrevNextJobs.prevJob}
                      direction="prev"
                      color={buttonColor}
                      className={clsx(buttonClass, pageNavTrackingClasses)}
                    />
                  )}
                  {apiPrevNextJobs?.nextJob && (
                    <NavButton
                      navigationJob={apiPrevNextJobs?.nextJob}
                      direction="next"
                      color={buttonColor}
                      className={clsx(buttonClass, pageNavTrackingClasses)}
                    />
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>

      {/* Share modal */}
      {job && (
        <ShareModal
          isOpen={isOpenShareModal}
          onClose={toggleShareModal}
          ogTitle={job?.title}
          ogDescription={job.title}
          referralCode={headerInfo?.userSlug || undefined}
        />
      )}
    </div>
  );
};
