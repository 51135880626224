import { useState } from 'react';
import { useBuyNeuroCandidate } from '@hooks/credits/useBuyNeuroCandidate';
import { StatusPayment } from '@components/features/payment';
import { useTranslation } from 'next-i18next';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { LinkStyled } from '@components/common';
import { PaymentMethodsCandidate } from './partials/PaymentMethodsCandidate';

/**
 * @description Hub interface.
 */
interface BuyNeuroHubProps {
  closeModal: () => void,
  jobSlug?: string,
  successCallback?: () => void
}

/**
 * @description Common transition props.
 */
const transitionProps = {
  show: true,
  appear: true,
  unmount: false,
  enter: 'transition-opacity duration-300',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
};

/**
 * @description Renders the Buy Neuro hub for modal.
 * - `closeModal` - Function to close the modal.
 */
export const UserBuyNeuroHub = (props: BuyNeuroHubProps) => {
  // Destructure props
  const {
    closeModal,
    jobSlug = '',
    successCallback,
  } = props;

  // State for the current step. Use the component names as steps to avoid mismatches.
  const [currentStep, setCurrentStep] = useState<string>('PaymentMethods');
  // Translations
  const { t } = useTranslation('common');

  // Buy neuro hook
  const {
    cartDetails,
    orderCredits,
    savingOrder,
    paths,
    loadingCartDetails,
  } = useBuyNeuroCandidate({
    stepSetter: setCurrentStep,
    successCallbackFunction: successCallback,
  });

  // Render component
  // ********************************
  return (
    <>
      {/* Step 2 - Payment methods */
          (currentStep === 'PaymentMethods' && !loadingCartDetails) && (
            <div className="sm:!min-w-102">
              <div className="px-6 pt-3">
                {jobSlug && (
                <div className="mb-3 flex border border-warning bg-warning/10 p-4">
                  <ExclamationTriangleIcon className="mr-2 size-6 text-warning" />
                  <div>
                    <span className="font-bold">
                      {t('global.evaluation.modal.453.title')}
                    </span>
                    <p>
                      {t('global.evaluation.modal.453.description')}
                    </p>
                    <LinkStyled
                      href="/neuro"
                      color="ink"
                      styling="text"
                      className="underline"
                    >
                      {t('neuro.description.title')}
                    </LinkStyled>
                  </div>
                </div>
                )}
                <p>
                  {t('neuro.candidate.buy.description')}
                </p>
              </div>
              <PaymentMethodsCandidate
                transitionProps={transitionProps}
                cartDetails={cartDetails}
                orderCredits={orderCredits}
                savingOrder={savingOrder}
                jobSlug={jobSlug}
              />
            </div>
          )
      }

      {/* Step 3a - Completed & paid with credit card */
        currentStep === 'CompletedCard' && (
          <StatusPayment
            orderStatus="success"
            customTitle="payment.success.title_candidates"
            customMessage="payment.checkout.payment.invoice_candidates"
            actionSuccess={closeModal}
          />
        )
      }

      {/* Step 3b - Completed but not paid (invoice) */
        currentStep === 'CompletedInvoice' && (
          <StatusPayment
            orderStatus="success"
            customTitle="order.success.title_candidates"
            customMessage="order.checkout.payment.invoice_candidates"
            customButtonLabel="page.after.activation.job.button.invoices"
            withAction={paths?.toInvoice !== undefined}
            actionSuccess={paths?.toInvoice}
          />
        )
      }

      {/* Step 3c - Not completed */
        currentStep === 'NotCompleted' && (
          <StatusPayment
            orderStatus="error"
            customTitle="order.failed.title_candidates"
            customMessage="order.failed.message_candidate"
            customButtonLabel="global.label.see_details"
            withAction={paths?.notCompleted !== undefined}
            actionError={paths?.notCompleted}
          />
        )
      }

      {/* Step 3d - Error */
        currentStep === 'Error' && (
          <StatusPayment
            orderStatus="error"
            customTitle="payment.error.title_candidates"
            customMessage="payment.error.message_candidate"
            actionError={() => setCurrentStep('PaymentMethods')}
          />
        )
      }
    </>
  );
};
