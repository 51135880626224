import { JobEscoSkill } from '@type/v1-api-types';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { useSwrResumeSkillAdd } from '@hooks/useSwrApi/endpoints/resume';
import { clsx } from 'clsx';
import { CheckIcon, PlusIcon } from '@heroicons/react/20/solid';
import { TailwindSpinner } from '@components/common';
import { toSeparatorCase } from '@utils/strings/convertCase';
import { Tag } from './Tag';

// Interface
// ************************************
interface SkillTagProps {
  skill: JobEscoSkill,
  incrementSkillsCounter: () => void,
  locale: Locale,
  userRole?: 'USER' | 'COMPANY',
  className?: string;
}

/**
 * @description SkillTag. Component that renders a skill as a link (if verified) or as a simple button.
 * It also contains the logic to add a skill to the user's resume.
 * - **skill** - JobEscoSkill - The skill to display.
 * - **incrementSkillsCounter** - () => void - Callback function that is executed after a skill is successfully added to the resume.
 * - **locale** - Locale - The current app locale.
 * - **userRole** - 'USER' | 'COMPANY' - The role of the current user.
 * - **className** - string - Additional classes to be added to the root element, for styling.
 */
export const SkillTag = (props: SkillTagProps) => {
  // Destructure props
  const {
    skill,
    incrementSkillsCounter,
    locale,
    userRole,
    className,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  // Helpers
  // ************************************
  // Checks if the skill is verified
  const isVerified = useMemo(
    () => !skill.addedByUser || (!skill.excluded && skill.validated),
    [skill],
  );

  // Checks if the skill can be added to the resume.
  const canAdd = useMemo(
    () => userRole === 'USER' && isVerified && !skill.matched,
    [skill.matched, userRole, isVerified],
  );

  // Displayed label
  const skillLabel = useMemo(
    () => skill.preferredLabel?.trim(),
    [skill],
  );

  // SWR Mutation: Add Skill to resume
  // ************************************
  const { trigger, isMutating } = useSwrResumeSkillAdd(skill?.id || '', locale, () => {
    if (skill) {
      skill.matched = true;
      incrementSkillsCounter();
    }
  }, t('job.detail.skills.toast.skill-added', { skill: skillLabel }));

  // Don't render if skill has no label
  // ************************************
  if (!skillLabel) {
    return null;
  }

  // Add Skill to resume
  const addSkillToResume = () => {
    if (!isMutating && canAdd) {
      void trigger();
    }
  };

  // Render component
  // ************************************
  return (
    <Tag
      slug={!canAdd && isVerified ? toSeparatorCase(skillLabel, { separator: '+', decamelize: false }) : undefined}
      {...isVerified && !skill.linkable ? { rel: 'nofollow noopener noreferrer' } : {}}
      onClick={addSkillToResume}
      className={clsx(className, { 'pointer-events-none': !isVerified })}
    >
      {skill.matched && (
        <CheckIcon className="mr-1.5 size-4 shrink-0 text-secondary" />
      )}
      {canAdd && !isMutating && (
        <PlusIcon className="mr-1.5 size-4 shrink-0" />
      )}
      {canAdd && isMutating && (
        <TailwindSpinner className="mr-1.5 size-4 shrink-0" />
      )}
      { skillLabel }
    </Tag>
  );
};
