import { useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { ApiJobDetailOk } from '@type/v1-api-types';
import { CurrencyDollarIcon } from '@heroicons/react/24/outline';
import { EstimatedSalaryInfoModal } from '@components/templates/job/detail/sections/EstimatedSalaryInfoModal';
import { JobLocations } from '@components/templates/job/detail/partials/JobLocations';
import { JobCareerLevels } from '@components/templates/job/detail/partials/JobCareerLevels';

// Interface
// ************************************
export interface SummaryProps {
  job: ApiJobDetailOk;
}

/**
 * @description Job Detail Summary.
 * - **job** - ApiJobDetailOk
 */
export const Summary = (props: SummaryProps) => {
  // Destructure props
  const { job } = props;

  // Translation
  const { t } = useTranslation('common');

  // Extra summary info
  const extraSummary: string[] = useMemo(
    () => {
      const extra: string[] = [];

      // relocation
      if (job.relocation) {
        extra.push(t('job.detail.relocation'));
      }

      // employment types
      if (job.employmentTypes?.length) {
        extra.push(job.employmentTypes.join(', '));
      }

      // vacancies
      if (job.vacancies) {
        extra.push(t('job.detail.react.vacancies', { count: job.vacancies }));
      }

      // work schedule
      if (job.workSchedule) {
        extra.push(job.workSchedule);
      }

      return extra;
    },
    [job, t],
  );

  // Estimated Salary Info Modal
  const [showEstimatedSalaryModal, setShowEstimatedSalaryModal] = useState<boolean>(false);

  const showEstimatedSalaryInfoModal = () => {
    setShowEstimatedSalaryModal(true);
  };

  const closeEstimatedSalaryInfoModal = () => {
    setShowEstimatedSalaryModal(false);
  };

  // Render component
  // ************************************
  return (
    <>
      <div className="mt-8 space-y-2 text-sm text-ink-medium">

        {/* Salary */
          (job.salary || job.salaryConfidential) && (
            <div className={`flex${job.salary ? ' items-center' : ''}`}>
              <CurrencyDollarIcon className="mt-px size-4 shrink-0" />
              <div className="ml-2">
                {job.salary && (
                  <span className="text-base font-bold">{job.salary}</span>
                )}
                {job.salaryConfidential && (
                  <>
                    <strong>
                      { t('job.detail.confidential.salary') }
                    </strong>
                    {job.estimatedSalary && (
                      <div>
                        {t('salary-estimate-similar-employees')}
                        <span
                          role="button"
                          tabIndex={0}
                          className="ml-1 cursor-pointer text-primary transition-colors duration-200 hover:text-primary-light hover:underline"
                          onClick={showEstimatedSalaryInfoModal}
                          onKeyDown={showEstimatedSalaryInfoModal}
                        >
                          {job.estimatedSalary}
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )
        }

        {/* Career Levels */}
        <JobCareerLevels careerLevels={job.careerLevels} />

        {/* Job locations */}
        <JobLocations locations={job.locations} remote={job.remote} partialRemote={job.partialRemote} maxDisplayedLocations={1} withTooltip />

        {/* Extra summary info */}
        <div className="ml-6">
          {extraSummary.join('; ')}
        </div>
      </div>

      {/* Estimated salary info modal */}
      {job.estimatedSalary && (
        <EstimatedSalaryInfoModal
          job={job}
          isOpen={showEstimatedSalaryModal}
          onClose={closeEstimatedSalaryInfoModal}
        />
      )}

    </>
  );
};
