import { useMemo } from 'react';
import { generateNumbersArray } from '@utils/arrays/generateNumbersArray';

/**
 * @description Job Detail Tags Placeholder.
 */
export const TagsPlaceholder = () => {
  const tagsItems = useMemo(
    () => generateNumbersArray(1, 8),
    [],
  );

  return (
    <div className="flex animate-pulse flex-wrap items-center gap-4">
      {tagsItems.map((index) => (
        <div key={`${index}-`} className="h-8 w-24 rounded-full bg-slate-200" />
      ))}
    </div>
  );
};
