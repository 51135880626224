import { detailSectionsStyles } from '@components/templates/job/detail';

/**
 * @description Job Detail Company Widget Placeholder.
 */
export const CompanyWidgetPlaceholder = () => (
  <div className={detailSectionsStyles.common}>
    <div className="h-18 w-full animate-pulse rounded bg-slate-200" />
  </div>
);
