/**
 * @description Job Detail Company Short Placeholder.
 */
export const CompanyShortPlaceholder = () => (
  <div className="mt-11 flex w-64 animate-pulse items-center">
    <div className="mr-4 size-15 rounded-full bg-slate-400" />
    <div className="flex-1 space-y-4">
      <div className="h-3 w-full rounded bg-slate-400" />
      <div className="h-2 w-full rounded bg-slate-400" />
    </div>
  </div>
);
